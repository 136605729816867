import React from 'react';
// import Header from '../Header';
import BottomBar from '../BottomBar';
import Header from '../Header';


export default function Shape() {
  return (
    <div>
      <Header/>
      <div className="blank-container"></div>
      <h1>Comming Soon !</h1>
      <BottomBar/>
    </div>
  )
}
